import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { THIRD_PARTY_TYPE_ENUM } from 'utils/enums';
import { context as userContext } from 'context/user';
import { MerchantDeliveryActionButtons } from './OrderDetailsStatus';
import { DeliveryActionButtons } from './OrderDetailsStatus/DeliveryActionButtons';
import { isDeliveryOrder as isDeliveryOrderHelper } from '../helpers';

const OrderDetailsActionButtons = ({
  order,
  isUpdatingStatus,
  shouldDisableActionBtns,
  sendChangeStatus,
  deliveryType,
}) => {
  const { deliveryType: orderDeliveryType } = order || {};
  const {
    selectedStore: { restaurantCourierSetting },
    settings,
  } = useContext(userContext);
  const isDeliveryOrder = order && isDeliveryOrderHelper(orderDeliveryType);
  const isVerd = settings.enableVerd;
  const filteredRestaurantCourierSetting = restaurantCourierSetting.filter(
    courier => !courier.isInternalDelivery && courier.businessType === THIRD_PARTY_TYPE_ENUM.Delivery,
  );

  const onFleetCourierActions = (
    <DeliveryActionButtons
      order={order}
      isUpdatingStatus={isUpdatingStatus}
      shouldDisableActionBtns={shouldDisableActionBtns}
      sendChangeStatus={sendChangeStatus}
      deliveryType={deliveryType}
    />
  );

  const noCourierActions = (
    <MerchantDeliveryActionButtons
      order={order}
      isUpdatingStatus={isUpdatingStatus}
      shouldDisableActionBtns={shouldDisableActionBtns}
      sendChangeStatus={sendChangeStatus}
      deliveryType={deliveryType}
    />
  );

  if (isDeliveryOrder && (isVerd || filteredRestaurantCourierSetting.length)) return onFleetCourierActions;

  return noCourierActions;
};

OrderDetailsActionButtons.propTypes = {
  order: PropTypes.shape({
    expectedAt: PropTypes.string.isRequired,
    deliveryType: PropTypes.string.isRequired,
    number: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  }),
  isUpdatingStatus: PropTypes.bool.isRequired,
  shouldDisableActionBtns: PropTypes.bool,
  sendChangeStatus: PropTypes.func.isRequired,
  selectedStore: PropTypes.shape({ timeZone: PropTypes.string }).isRequired,
  deliveryType: PropTypes.string.isRequired,
};
export default OrderDetailsActionButtons;
