import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@zydalabs/zac-react';

import { ORDER_STATUS } from 'constants/order';
import { DELIVERY_BUSY_MODE } from 'components/common/branch/Busy/utils';
import { Text } from 'components/service';
import * as translations from 'constants/translations';

export const MerchantDeliveryActionButtons = ({
  order,
  isUpdatingStatus,
  shouldDisableActionBtns,
  sendChangeStatus,
  deliveryType,
}) => {
  const { status: orderStatus } = order || {};
  const showNextStatus = () => {
    switch (orderStatus) {
      case ORDER_STATUS.SUBMITTED:
        return (
          <Button
            rounded
            isLoading={isUpdatingStatus}
            isDisabled={shouldDisableActionBtns}
            onClick={() => sendChangeStatus({ status: ORDER_STATUS.ACCEPTED, sendCourierId: false })}
            size="medium"
            text={<Text value={translations.ACCEPT_ORDER} />}
            data-testid="accept-order-button"
          />
        );
      case ORDER_STATUS.ACCEPTED:
        return (
          <Button
            rounded
            isLoading={isUpdatingStatus}
            isDisabled={shouldDisableActionBtns}
            onClick={() => sendChangeStatus({ status: ORDER_STATUS.READY, sendCourierId: false })}
            size="medium"
            text={<Text value={translations.READY_ORDER} />}
            data-testid="ready-order-button"
          />
        );
      case ORDER_STATUS.READY:
        if (deliveryType === DELIVERY_BUSY_MODE.PICKUP || deliveryType === DELIVERY_BUSY_MODE.BEACH) {
          return (
            <Button
              rounded
              isLoading={isUpdatingStatus}
              isDisabled={shouldDisableActionBtns}
              onClick={() => sendChangeStatus({ status: ORDER_STATUS.FULFILLED, sendCourierId: false })}
              size="medium"
              text={<Text value={translations.FULFILL_ORDER} />}
              data-testid="fulfill-order-button"
            />
          );
        }
        return (
          <Button
            rounded
            isLoading={isUpdatingStatus}
            isDisabled={shouldDisableActionBtns}
            onClick={() => sendChangeStatus({ status: ORDER_STATUS.DISPATCHED, sendCourierId: false })}
            size="medium"
            text={<Text value={translations.DISPATCH_ORDER} />}
            data-testid="dispatch-order-button"
          />
        );

      case ORDER_STATUS.DISPATCHED:
        return (
          <Button
            rounded
            isLoading={isUpdatingStatus}
            isDisabled={shouldDisableActionBtns}
            onClick={() => sendChangeStatus({ status: ORDER_STATUS.DELIVERED, sendCourierId: false })}
            size="medium"
            text={<Text value={translations.DELIVER_ORDER} />}
            data-testid="deliver-order-button"
          />
        );
      case ORDER_STATUS.DELIVERED:
      default:
        return null;
    }
  };
  return showNextStatus();
};

MerchantDeliveryActionButtons.propTypes = {
  order: PropTypes.shape({ status: PropTypes.string.isRequired }),
  isUpdatingStatus: PropTypes.bool.isRequired,
  shouldDisableActionBtns: PropTypes.bool,
  sendChangeStatus: PropTypes.func.isRequired,
  deliveryType: PropTypes.string.isRequired,
  openOrderDeliveryConfirmationPopUp: PropTypes.func.isRequired,
};
export default MerchantDeliveryActionButtons;
