import { gql } from '@apollo/client';

export const BRANCHES = gql`
  query Branches($restaurantId: String!) {
    branches(restaurantId: $restaurantId) {
      id
      titleEn
      titleAr
      lat
      lng
    }
  }
`;

export const SETTINGS = gql`
  query($storeId: String!) {
    settings(restaurantId: $storeId) {
      id
      isAutoAccept
      cardondeliveryPaymentEnabled
    }
  }
`;

export const AUTHORIZE = gql`
  mutation authorizeCourier(
    $apiKey: String!
    $courierName: String!
    $restaurantId: String!
    $deliveryDurationType: String
  ) {
    authorizeCourier(
      apiKey: $apiKey
      courierName: $courierName
      restaurantId: $restaurantId
      deliveryDurationType: $deliveryDurationType
    )
  }
`;

export const AUTHORIZE_BRANCHES = gql`
  mutation authorizeCourierBranches(
    $apiKey: [BranchAPIKey]!
    $courierName: String!
    $restaurantId: String!
    $deliveryDurationType: String
  ) {
    authorizeCourierBranches(
      apiKey: $apiKey
      courierName: $courierName
      restaurantId: $restaurantId
      deliveryDurationType: $deliveryDurationType
    )
  }
`;

export const RESTAURANT = gql`
  query($storeId: String!) {
    restaurant(id: $storeId) {
      id
      createdAt
      titleEn
      titleAr
      descriptionEn
      descriptionAr
      fbadTrackId
      defaultProductPrepTime
      gaTrackId
      snapTrackId
      gtmTrackId
      capiAccessToken
      taxPercentage
      taxInclusive
      countryId
      isStockEnabled
      published
      logoUrl
      timeZone
      countryLat
      countryLng
      fullUrl
      countryCode
      paymentMethods
      blockTabs
      currency {
        decimals
        titleEn
        titleAr
      }
      preferredLanguage
      subscriptionPlanType
      restaurantCourierSetting {
        courierId
        isOtherDeliveryEnabled
        businessType
        courierDetails {
          id
          name
          country
          description
          supportNumber
          businessId
          businessName
        }
        isManualStatusUpdate
        authenticationType
        isInternalDelivery
        driverMaxCapacity
        isMenuPull
        supportCancellation
        isBranchListingEnabled
        isTaxInclusive
        isFeeFromCourier
        isTimeFromCourier
        supportMsValidationRequest
        default
      }
    }
  }
`;

export const DELETE_COURIER_SETTINGS = gql`
  mutation deleteCourierSettings($restaurantId: String!, $courierId: String!) {
    deleteCourierSettings(restaurantId: $restaurantId, courierId: $courierId)
  }
`;

export const CONNECTED_DELIVERECT_BRANCHES = gql`
  query($storeId: String!) {
    connectedPosBranches(storeId: $storeId) {
      branchInternalId
      branchExternalId
      partnerName
    }
  }
`;

export const UPDATE_COURIER_SETTINGS = gql`
  mutation updateCourierSettings(
    $restaurantId: String!
    $courierId: String!
    $isTimeFromCourier: Boolean
    $isFeeFromCourier: Boolean
    $isDefault: Boolean
  ) {
    updateCourierSettings(
      restaurantId: $restaurantId
      courierId: $courierId
      isTimeFromCourier: $isTimeFromCourier
      isFeeFromCourier: $isFeeFromCourier
      isDefault: $isDefault
    ) {
      courierId
      isOtherDeliveryEnabled
      businessType
      courierDetails {
        id
        name
        country
        description
        supportNumber
        businessId
        businessName
      }
      isManualStatusUpdate
      authenticationType
      isInternalDelivery
      driverMaxCapacity
      isMenuPull
      isBranchListingEnabled
      isTaxInclusive
      isTimeFromCourier
      isFeeFromCourier
      supportMsValidationRequest
      default
    }
  }
`;
