import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Button } from '@zydalabs/zac-react';

import { Modal } from 'components/kit';
import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import { ORDER_STATUS } from 'constants/order';
import { DELIVERY_BUSY_MODE } from 'components/common/branch/Busy/utils';
import { Text } from 'components/service';
import * as translations from 'constants/translations';
import { THIRD_PARTY_TYPE_ENUM } from 'utils/enums';
import { useAssignScheduledOrderNow, useFetchVerdDrivers } from 'service/hooks';
import { useSelectedStore } from 'hooks';
import { ORDER_RIDER_STATUS } from 'constants/orderRiderStatus';
import useHandleAssignUnAssignCourierToOrder from './handleAssignCourierToOrder';
import AddRiderModal from './AddRiderModal';
import useHandleAssignUnAssignDriverToOrder from './handleAssignDrivertoOrder';

export const DeliveryActionButtons = ({
  order,
  isUpdatingStatus,
  shouldDisableActionBtns,
  sendChangeStatus,
  deliveryType,
}) => {
  const { status: orderStatus, branchData } = order || {};
  const { lang, translate } = useContext(localeContext);
  const {
    selectedStore: { restaurantCourierSetting },
  } = useContext(userContext);
  const { user } = useContext(userContext);
  const assignScheduledOrderNow = useAssignScheduledOrderNow();
  const selectedStoreId = useSelectedStore();
  const { data: activeRiders, mutate: mutateFetchVerdDrivers } = useFetchVerdDrivers({
    storeId: selectedStoreId,
    branchId: parseInt(branchData.id),
  });
  const verd = restaurantCourierSetting?.find(courier => courier.isInternalDelivery);

  const [shouldShowRiderModal, setShouldShowRiderModal] = useState(verd?.showRiderModal || false);

  const riders = activeRiders?.length ? activeRiders.filter(rider => rider.onShift) : [];
  const deliveryOrderStatus = order?.deliveryStatus;
  const isOrderDeliveryCourierNotVerd = !order?.deliveryCourier?.isInternalDelivery;
  const isScheduledOrder = order?.isScheduled;
  const isInternalDelivery = order?.deliveryCourier?.isInternalDelivery;
  const isPendingScheduledOrder = isScheduledOrder && deliveryOrderStatus === ORDER_RIDER_STATUS.PENDING;
  const orderDeliveryCourierId = order?.deliveryCourier?.courierId;
  const quickAssignCourierId = order?.deliveryCourierId;
  const filteredRestaurantCourierSetting = restaurantCourierSetting.filter(
    courier => !courier.isInternalDelivery && courier.businessType === THIRD_PARTY_TYPE_ENUM.Delivery,
  );
  const assignedCourierId = Number(
    filteredRestaurantCourierSetting?.find(courier => Number(courier.courierId) === orderDeliveryCourierId)?.courierId,
  );
  const quickAssignCourier = restaurantCourierSetting.find(
    courier => Number(courier.courierId) === quickAssignCourierId,
  );
  const quickAssignCourierIsVerd = quickAssignCourier?.isInternalDelivery;

  const { handleAssignCourierToOrder } = useHandleAssignUnAssignCourierToOrder({
    order,
    mutateFetchVerdDrivers,
  });

  const { handleAssignDriverToOrder } = useHandleAssignUnAssignDriverToOrder({
    order,
    mutateFetchVerdDrivers,
  });

  const accpetAndAssignAction = () => {
    sendChangeStatus({ status: ORDER_STATUS.ACCEPTED, sendCourierId: false });
    handleAssignCourierToOrder(quickAssignCourier);
  };

  const hasCourierInfo = orderDeliveryCourierId && isOrderDeliveryCourierNotVerd;
  const hasRiders = riders?.length !== 0;

  const AddRiderModalBody = (close, closeable) => (
    <AddRiderModal
      close={close}
      closeable={closeable}
      mutateFetchVerdDrivers={mutateFetchVerdDrivers}
      sendChangeStatus={sendChangeStatus}
      handleAssignDriverToOrder={handleAssignDriverToOrder}
      setShouldShowRiderModal={setShouldShowRiderModal}
    />
  );

  const ShowNextStatus = () => {
    switch (orderStatus) {
      case ORDER_STATUS.SUBMITTED:
        return (
          <div className={cx('flex flex-row', lang === 'ar' && 'flex-row-reverse')}>
            {assignedCourierId || quickAssignCourierIsVerd || !quickAssignCourierId ? (
              <Modal>
                {({ open, close, closeable }) => (
                  <div>
                    <Button
                      rounded
                      isLoading={isUpdatingStatus}
                      isDisabled={shouldDisableActionBtns}
                      onClick={() =>
                        !(hasRiders || hasCourierInfo) && shouldShowRiderModal
                          ? open({
                              body: AddRiderModalBody(close, closeable),
                            })
                          : sendChangeStatus({ status: ORDER_STATUS.ACCEPTED, sendCourierId: false })
                      }
                      size="medium"
                      text={translate(translations.ACCEPT_ORDER)}
                      data-testid="accept-order-button"
                    />
                  </div>
                )}
              </Modal>
            ) : (
              <div className="flex gap-4">
                <Modal>
                  {({ open, close, closeable }) => (
                    <div>
                      <Button
                        rounded
                        isLoading={isUpdatingStatus}
                        isDisabled={shouldDisableActionBtns}
                        onClick={() =>
                          !(hasRiders || hasCourierInfo) && shouldShowRiderModal
                            ? open({
                                body: AddRiderModalBody(close, closeable),
                              })
                            : sendChangeStatus({ status: ORDER_STATUS.ACCEPTED, sendCourierId: false })
                        }
                        size="medium"
                        variant="tertiary"
                        text={translate(translations.ACCEPT_ONLY_ORDER)}
                        data-testid="accept-order-button"
                      />
                    </div>
                  )}
                </Modal>
                <div>
                  <Button
                    rounded
                    isLoading={isUpdatingStatus}
                    isDisabled={shouldDisableActionBtns}
                    onClick={() => {
                      accpetAndAssignAction();
                    }}
                    size="medium"
                    text={translate(translations.ACCEPT_AND_ASSIGN)}
                    data-testid="accept-order-button"
                  />
                </div>
              </div>
            )}
          </div>
        );
      case ORDER_STATUS.ACCEPTED:
        return (
          <Button
            rounded
            isLoading={isUpdatingStatus}
            isDisabled={shouldDisableActionBtns}
            onClick={() => {
              sendChangeStatus({ status: ORDER_STATUS.READY, sendCourierId: false });
            }}
            size="medium"
            text={translate(translations.READY_ORDER)}
            data-testid="ready-order-button"
          />
        );
      case ORDER_STATUS.READY:
        if (deliveryType === DELIVERY_BUSY_MODE.PICKUP || deliveryType === DELIVERY_BUSY_MODE.BEACH) {
          return (
            <Button
              rounded
              isLoading={isUpdatingStatus}
              isDisabled={shouldDisableActionBtns}
              onClick={() => {
                sendChangeStatus({ status: ORDER_STATUS.FULFILLED, sendCourierId: false });
              }}
              size="medium"
              text={translate(translations.FULFILL_ORDER)}
              data-testid="fulfill-order-button"
            />
          );
        }
        return (
          <Button
            rounded
            isLoading={isUpdatingStatus}
            isDisabled={shouldDisableActionBtns}
            onClick={() => {
              sendChangeStatus({ status: ORDER_STATUS.DISPATCHED, sendCourierId: false });
            }}
            size="medium"
            text={translate(translations.DISPATCH_ORDER)}
            data-testid="dispatch-order-button"
          />
        );

      case ORDER_STATUS.DISPATCHED:
        return (
          <Button
            rounded
            isLoading={isUpdatingStatus}
            isDisabled={shouldDisableActionBtns}
            onClick={() => {
              sendChangeStatus({ status: ORDER_STATUS.DELIVERED, sendCourierId: false });
            }}
            size="medium"
            text={translate(translations.DELIVER_ORDER)}
            data-testid="deliver-order-button"
          />
        );
      case ORDER_STATUS.DELIVERED:
      default:
        return null;
    }
  };

  const AssignScheduledNow = () => (
    <Button
      rounded
      isLoading={isUpdatingStatus}
      isDisabled={shouldDisableActionBtns}
      onClick={() => {
        assignScheduledOrderNow({
          orderNumber: order.number,
          userId: user?.id,
        });
      }}
      size="medium"
      variant="tertiary"
      text={<Text value={translations.DELIVER_NOW} />}
      data-testid="assign-now-button"
    />
  );

  return isPendingScheduledOrder && !isInternalDelivery ? (
    <div className="flex flex-row justify-between">
      <div className={cx(lang === 'ar' ? 'ml-4' : 'mr-4')}>
        <AssignScheduledNow />
      </div>
      <ShowNextStatus />
    </div>
  ) : (
    <div className="flex justify-end">
      <ShowNextStatus />
    </div>
  );
};

DeliveryActionButtons.propTypes = {
  order: PropTypes.shape({ status: PropTypes.string.isRequired }),
  isUpdatingStatus: PropTypes.bool.isRequired,
  shouldDisableActionBtns: PropTypes.bool,
  sendChangeStatus: PropTypes.func.isRequired,
  deliveryType: PropTypes.string.isRequired,
};
export default DeliveryActionButtons;
