import React, { useContext } from 'react';
import { Query } from '@apollo/client/react/components';
import { Spinner } from 'components/kit';
import { useMutation } from '@apollo/client';
import { navigate } from '@reach/router';

import { context as userContext } from 'context/user';
import { context as localeContext } from 'context/locale';
import { useReplaceParams } from 'hooks/index';
import { Layout } from 'components/common/dashboard';
import { BranchForm } from 'components/common/branch';
import { Modal } from 'components/kit';
import { Text } from 'components/service';
import * as paths from 'paths.js';
import * as schemas from './schemas';
import * as utils from './utils';
import TopPane from './TopPane';
import cx from 'classnames';
import { context as notificationsContext } from 'context/notifications';
import { SOMETHING_WENT_WRONG, EXTERNAL_ID_REQUIRED } from 'constants/translations';
import { STH_WENT_WRONG } from 'constants/constant-errors';
import { RESTAURANT_ORDER_PICKUP_TYPE } from 'constants/restaurant';

const BranchEdition = ({ branchId }) => {
  const { selectedStore, isPosCourier, isDeliveryCourier } = useContext(userContext);
  const selectedStoreId = selectedStore.id;
  const notifications = useContext(notificationsContext);
  const restaurantId = selectedStoreId.toString();
  const { lang } = useContext(localeContext);
  const replace = useReplaceParams();

  const [updateBranch, { loading: isUpdating }] = useMutation(schemas.UPDATE_BRANCH, {
    onError: err => {
      const { body } = err.graphQLErrors[0]?.extensions?.exception || {};
      const map = {
        force_scheduled_threshold: 'Max Order Fulfillment Period',
      };
      if (body.external_id) {
        const message = EXTERNAL_ID_REQUIRED;
        return notifications.show(message[lang === 'en' ? 0 : 1], 'error');
      } else if (Object.keys(body).length) {
        for (let key of Object.keys(body)) {
          return notifications.show(`${map[key] || key} ${body[key][0]}`, 'error');
        }
      } else {
        return notifications.show(<Text value={SOMETHING_WENT_WRONG} />, 'error');
      }
    },
    onCompleted: () => navigate(replace(paths.branchList)),
  });

  return (
    <Modal isMenu>
      {({ open, close }) => {
        return (
          <Layout>
            <div className={cx('px-3 pb-6 flex', lang === 'ar' && 'flex-row-reverse')}>
              <Query
                query={schemas.GET_BRANCH}
                variables={{
                  id: branchId,
                  restaurantId,
                  isPOSIntegrated: isPosCourier,
                  isCourierIntegrated: isDeliveryCourier,
                }}
                fetchPolicy="cache-and-network"
              >
                {({ loading: isLoading, error, data }) => {
                  if (!data)
                    return (
                      <div className="mt-2 px-1">
                        <Spinner />
                      </div>
                    );
                  const { branch } = data;
                  return isLoading ? (
                    <div className="mt-6">
                      <Spinner />
                    </div>
                  ) : (
                    <div className="flex-col">
                      <BranchForm
                        branchId={branchId}
                        isSubmitting={isUpdating}
                        renderTopPane={({ isSubmitting, isValidating }) => (
                          <TopPane storeId={restaurantId} isSubmitting={isSubmitting || isValidating} branch={branch} />
                        )}
                        initialValues={{
                          title: {
                            en: branch.titleEn,
                            ar: branch.titleAr,
                          },
                          address: {
                            en: branch.addressEn,
                            ar: branch.addressAr,
                          },
                          location: {
                            lat: branch.lat,
                            lng: branch.lng,
                          },
                          ...branch,
                          posBranchId: data?.posBranches && data?.posBranches[0]?.branchExternalId,
                          courierBranchId: data?.courierBranches && data?.courierBranches[0]?.branchExternalId,
                          pickupOrdersType: !branch.pickupOrdersType
                            ? RESTAURANT_ORDER_PICKUP_TYPE.STORE_PICKUP
                            : branch.pickupOrdersType,
                        }}
                        openModal={open}
                        closeModal={close}
                        onSubmit={async ({
                          titleEn,
                          titleAr,
                          addressEn,
                          addressAr,
                          buildingNumber,
                          street,
                          cityId,
                          block,
                          deliveryNotes,
                          location,
                          contactNumber,
                          dineIn,
                          pickupOrdersEnabled,
                          pickupOrdersType,
                          deliveryOrdersEnabled,
                          beachOrderEnabled,
                          openingHours,
                          forceScheduledThreshold,
                        }) => {
                          try {
                            await updateBranch({
                              variables: {
                                id: branchId,
                                restaurantId: selectedStoreId,
                                titleEn: titleEn,
                                titleAr: titleAr,
                                addressEn: addressEn,
                                addressAr: addressAr,
                                lat: location.lat,
                                lng: location.lng,
                                buildingNumber,
                                street,
                                cityId: parseInt(cityId),
                                block,
                                deliveryNotes,
                                contactNumber: contactNumber,
                                dineIn: dineIn,
                                pickupOrdersEnabled: pickupOrdersEnabled,
                                pickupOrdersType: pickupOrdersEnabled ? pickupOrdersType : null,
                                deliveryOrdersEnabled: deliveryOrdersEnabled,
                                beachOrderEnabled: beachOrderEnabled,
                                openingHours: utils.omitTypename(openingHours),
                                forceScheduledThreshold: forceScheduledThreshold,
                              },
                            });
                          } catch (error) {
                            if (!error.graphQLErrors) throw error;
                            notifications.show(STH_WENT_WRONG, 'error');
                          }
                        }}
                      />
                    </div>
                  );
                }}
              </Query>
            </div>
          </Layout>
        );
      }}
    </Modal>
  );
};

export default BranchEdition;
