import React, { useContext } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { context as localeContext } from 'context/locale';

const MultiDestination = ({ currency, title, value, size }) => {
  const { translate, direction, lang } = useContext(localeContext);
  return (
    <div
      className={cx(
        'flex justify-between w-full pb-1',
        lang === 'ar' && 'flex-row-reverse',
        size === 'text-sm' && 'px-4',
      )}
    >
      <span className={size}>{title}</span>
      <div className={size} style={{ direction }}>
        <span className={cx(lang === 'ar' ? 'ml-1' : 'mr-1')}>{translate(currency)}</span>
        {value.toFixed(currency.decimals)}
      </div>
    </div>
  );
};

MultiDestination.propTypes = {
  currency: PropTypes.shape({
    titleEn: PropTypes.string,
    titleAr: PropTypes.string,
    decimals: PropTypes.number.isRequired,
  }).isRequired,
  title: PropTypes.string,
  value: PropTypes.number,
  size: PropTypes.oneOf('text-xs', 'text-sm'),
};

export default MultiDestination;
