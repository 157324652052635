import React, { useContext } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { context as localeContext } from 'context/locale';
import { Text } from 'components/service';
import * as translations from 'constants/translations';

const OrderDetailsFees = ({ order, currency, deliveryType }) => {
  const { lang } = useContext(localeContext);
  const direction = lang === 'ar' ? 'rtl' : 'ltr';
  const {
    subtotal,
    discountedAmount,
    voucherCode,
    voucherAmount,
    subtotalAfterVoucher,
    taxPercentage,
    tax,
    deliveryFee,
    total,
    taxInclusive,
    cashbackAmount,
    verdFees,
    feastFees,
    engageFees,
  } = order || {};
  return (
    <div className="w-full">
      <div className="flex flex-col w-full mt-3 border-b-2 border-gray-200">
        <div className={cx('flex flex-row', lang === 'ar' && 'flex-row-reverse', 'justify-between pb-2')}>
          <span className="text-xs">
            <Text value={translations.SUBTOTAL} />
          </span>
          <span style={{ direction }} className="text-xs">
            <Text value={currency} className={cx('inline', lang === 'ar' ? 'ml-1' : 'mr-1')} />
            {subtotal.toFixed(currency.decimals)}
          </span>
        </div>
        {discountedAmount !== 0 && (
          <div className={cx('flex flex-row', lang === 'ar' && 'flex-row-reverse', 'justify-between pb-2')}>
            <span className="text-xs text-red-600">
              <Text value={translations.DISCOUNT} />
            </span>
            <span style={{ direction }} className="text-xs text-red-600">
              <Text value={currency} className={cx('inline', lang === 'ar' ? 'ml-1' : 'mr-1')} />
              {`-${discountedAmount.toFixed(currency.decimals)}`}
            </span>
          </div>
        )}
        {voucherCode && (
          <>
            <div className={cx('flex flex-row', lang === 'ar' && 'flex-row-reverse', 'justify-between pb-2')}>
              <span className="text-xs flex items-center text-red-600">
                <Text value={translations.COUPON_DISCOUNT} />
                &nbsp;({voucherCode})
              </span>
              <span className="text-xs text-red-600" style={{ direction }}>
                <Text value={currency} className={cx('inline', lang === 'ar' ? 'ml-1' : 'mr-1')} />
                {`-${voucherAmount.toFixed(currency.decimals)}`}
              </span>
            </div>
            <div className={cx('flex flex-row', lang === 'ar' && 'flex-row-reverse', 'justify-between pb-2')}>
              <span className="text-xs flex items-center">
                <Text value={translations.SUBTOTAL_AFTER_VOUCHER} />
              </span>
              <span style={{ direction }} className="text-xs">
                {subtotalAfterVoucher.toFixed(currency.decimals)}
                &nbsp;
              </span>
            </div>
          </>
        )}
        {!taxInclusive && (
          <div className={cx('flex flex-row', lang === 'ar' && 'flex-row-reverse', 'justify-between pb-2')}>
            <span className="text-xs flex items-center">
              <Text value={translations.TAX} />
              &nbsp;({taxPercentage}%)
            </span>
            <span style={{ direction }} className="text-xs">
              <Text value={currency} className={cx('inline', lang === 'ar' ? 'ml-1' : 'mr-1')} />
              {tax.toFixed(currency.decimals)}
            </span>
          </div>
        )}
        {deliveryType !== 'pickup' && (
          <div className={cx('flex flex-row', lang === 'ar' && 'flex-row-reverse', 'justify-between pb-2')}>
            <span className="text-xs">
              <Text value={translations.DELIVERY_FEES} />
            </span>
            <span style={{ direction }} className="text-xs">
              <Text value={currency} className={cx('inline', lang === 'ar' ? 'ml-1' : 'mr-1')} />
              {deliveryFee.toFixed(currency.decimals)}
            </span>
          </div>
        )}
        {cashbackAmount !== 0 && cashbackAmount > 0 && (
          <div className={cx('flex flex-row', lang === 'ar' && 'flex-row-reverse', 'justify-between pb-2')}>
            <span className="text-xs flex items-center text-black">
              <Text value={translations.CASHBACK_AMOUNT} />
            </span>
            <span style={{ direction }} className="text-xs">
              <Text value={currency} className={cx('inline', lang === 'ar' ? 'ml-1' : 'mr-1')} />-
              {cashbackAmount?.toFixed(currency?.decimals)}
            </span>
          </div>
        )}
        {taxInclusive && (
          <div className={cx('flex flex-row', lang === 'ar' && 'flex-row-reverse', 'justify-between pb-3')}>
            <span className="text-xs flex items-center">
              <Text value={translations.TAX_ARE_INCLUDED} payload={taxPercentage} />
            </span>
            <span style={{ direction }} className="text-xs">
              <Text value={currency} className={cx('inline', lang === 'ar' ? 'ml-1' : 'mr-1')} />
              {tax.toFixed(currency.decimals)}
            </span>
          </div>
        )}
        {!!verdFees && verdFees > 0 && (
          <div className={cx('flex flex-row', lang === 'ar' && 'flex-row-reverse', 'justify-between pb-2')}>
            <span className="text-xs flex items-center">
              <Text value={translations.VERD_FEES} />
            </span>
            <span style={{ direction }} className="text-xs">
              <Text value={currency} className={cx('inline', lang === 'ar' ? 'ml-1' : 'mr-1')} />
              {verdFees.toFixed(currency.decimals)}
            </span>
          </div>
        )}
        {!!feastFees && feastFees > 0 && (
          <div className={cx('flex flex-row', lang === 'ar' && 'flex-row-reverse', 'justify-between pb-2')}>
            <span className="text-xs flex items-center">
              <Text value={translations.DYMA_FEES} />
            </span>
            <span style={{ direction }} className="text-xs">
              <Text value={currency} className={cx('inline', lang === 'ar' ? 'ml-1' : 'mr-1')} />
              {feastFees.toFixed(currency.decimals)}
            </span>
          </div>
        )}
        {!!engageFees && engageFees > 0 && (
          <div className={cx('flex flex-row', lang === 'ar' && 'flex-row-reverse', 'justify-between pb-3')}>
            <span className="text-xs flex items-center">
              <Text value={translations.ENGAGE_FEES} />
            </span>
            <span style={{ direction }} className="text-xs">
              <Text value={currency} className={cx('inline', lang === 'ar' ? 'ml-1' : 'mr-1')} />
              {engageFees.toFixed(currency.decimals)}
            </span>
          </div>
        )}
      </div>
      <div
        className={cx(
          'flex flex-flex w-full mt-3 border-b-8 border-gray-100',
          lang === 'ar' && 'flex-row-reverse',
          ' justify-between pb-3',
        )}
      >
        <span className="text-sm font-semibold text-black">
          <Text value={translations.TOTAL} />
        </span>
        <span style={{ direction }} className="text-sm font-semibold text-black">
          <Text value={currency} className={cx('inline', lang === 'ar' ? 'ml-1' : 'mr-1')} />
          {total.toFixed(currency.decimals)}
        </span>
      </div>
    </div>
  );
};

OrderDetailsFees.propTypes = {
  order: PropTypes.shape({
    subtotal: PropTypes.number.isRequired,
    discountedAmount: PropTypes.number,
    voucherCode: PropTypes.string,
    voucherAmount: PropTypes.number,
    subtotalAfterVoucher: PropTypes.number.isRequired,
    taxPercentage: PropTypes.number.isRequired,
    tax: PropTypes.number.isRequired,
    taxInclusive: PropTypes.bool.isRequired,
    deliveryFee: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    verdFees: PropTypes.number.isRequired,
    feastFees: PropTypes.number.isRequired,
    engageFees: PropTypes.number.isRequired,
  }),
  currency: PropTypes.shape({
    decimals: PropTypes.number.isRequired,
    titleAr: PropTypes.string.isRequired,
    titleEn: PropTypes.string.isRequired,
  }),
  deliveryType: PropTypes.string,
};
export default OrderDetailsFees;
