import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';

import { useDeliveryZoneMsToken } from 'hooks';
import { Text, Field } from 'components/service';
import { Input } from 'components/form/elements';
import { Button } from 'components/kit';
import { Label } from 'components/form/generic';
import * as translations from 'constants/translations';
import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import { context as notificationsContext } from 'context/notifications';
import { VALIDATE_API_KEY, UPDATE_COURIER_BRANCH_ID } from './schemas';

const CourierIntegration = ({ branchId, setFieldValue, courierBranchId: defaultCourierBranchId }) => {
  const { lang } = useContext(localeContext);
  const notifications = useContext(notificationsContext);
  const {
    selectedStore: { id: storeId },
    courier,
  } = useContext(userContext);
  const [courierBranchId, setCourierBranchId] = useState(defaultCourierBranchId);
  const [isEditable, setIsEditable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const deliveryZoneMsToken = useDeliveryZoneMsToken();

  const [updateCourierBranchId] = useMutation(UPDATE_COURIER_BRANCH_ID, {
    ...(deliveryZoneMsToken && {
      headers: {
        token: deliveryZoneMsToken,
      },
    }),
    onCompleted: () => {
      setIsLoading(false);
      setIsEditable(false);
    },
    onError: () => {
      notifications.show(<Text value={translations.SOMETHING_WENT_WRONG} />, 'error');
    },
  });

  const [validateAPIKey] = useMutation(VALIDATE_API_KEY, {
    ...(deliveryZoneMsToken && {
      headers: {
        token: deliveryZoneMsToken,
      },
    }),
    onCompleted: data => {
      if (!data?.validateAPIKey?.valid) {
        setIsLoading(false);
        notifications.show(<Text value={translations.EXTERNAL_ID_INVALID} />, 'error');
        return;
      }

      if (!branchId) {
        setFieldValue('courierBranchId', courierBranchId);
        setIsLoading(false);
        setIsEditable(false);
        return;
      }

      updateCourierBranchId({
        variables: {
          storeId,
          branchInternalId: branchId,
          branchExternalId: courierBranchId,
          courierName: courier.courierDetails.name,
        },
      });
    },
    onError: () => {
      notifications.show(<Text value={translations.SOMETHING_WENT_WRONG} />, 'error');
    },
  });

  const checkValidation = key => {
    if (key?.length) {
      validateAPIKey({
        variables: {
          courierName: courier?.courierDetails.name,
          APIKey: key,
        },
      });
    } else {
      setIsLoading(false);
      notifications.show(<Text value={translations.EXTERNAL_ID_IS_REQUIRED} />, 'error');
    }
  };

  const handleEditable = e => {
    e.preventDefault();
    if (!isEditable) setIsEditable(true);
  };

  const handleUpdateCourierId = e => {
    e.preventDefault();
    setIsLoading(true);
    checkValidation(courierBranchId);
  };

  return (
    <div className="mb-2">
      <Text className="text-lg font-semibold px-4 -mx-4 pb-2" value={translations.DELIVERY_COURIER} />

      <div className="border border-gray-300 p-4">
        <div className="flex flex-row w-full">
          <div className="flex flex-col w-full pr-2">
            <Label
              title={
                <>
                  <Text
                    className="text-lg font-semibold px-4 -mx-4 pb-2 capitalize"
                    value={[courier?.courierDetails?.name, courier?.courierDetails?.name]}
                  />
                  <Text value={translations.INTEGRATIONS_TYPE_DESCRIPTION(courier?.courierDetails?.name)} />
                </>
              }
            >
              <Field
                type="text"
                name="courierBranchId"
                value={courierBranchId}
                placeholder={translations.ENTER_EXTERNAL_ID[lang === 'en' ? 0 : 1]}
                component={Input}
                onChange={e => {
                  setCourierBranchId(e.currentTarget.value);
                  setFieldValue('courierBranchId', e.currentTarget.value);
                }}
                disabled={!isEditable}
              />
            </Label>
          </div>

          <div className="flex items-end">
            {isEditable ? (
              <Button kind="primary" isSpinning={isLoading} onClick={handleUpdateCourierId}>
                <Text value={translations.SAVE_CHANGE} />
              </Button>
            ) : (
              <Button kind="secondary" onClick={handleEditable}>
                <Text value={translations.CHANGE} />
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

CourierIntegration.propTypes = {
  branchId: PropTypes.string.isRequired,
  courierBranchId: PropTypes.string,
  setFieldValue: PropTypes.func.isRequired,
};

export default CourierIntegration;
