import { gql } from '@apollo/client';

export const GET_BRANCH = gql`
  query Branch($id: String!, $restaurantId: String!, $isPOSIntegrated: Boolean, $isCourierIntegrated: Boolean) {
    branch(id: $id, restaurantId: $restaurantId) {
      id
      titleEn
      titleAr
      addressEn
      addressAr
      buildingNumber
      street
      cityId
      block
      deliveryNotes
      contactNumber
      externalId
      lat
      lng
      forceScheduledThreshold
      dineIn
      busy
      delivery
      pickupOrdersEnabled
      pickupOrdersType
      deliveryOrdersEnabled
      beachOrderEnabled
      openingHours {
        day
        openAt
        closeAt
        offDay
      }
    }
    posBranches(branchId: $id, isIntegrated: $isPOSIntegrated) {
      branchInternalId
      branchExternalId
      partnerName
    }
    courierBranches(branchId: $id, isIntegrated: $isCourierIntegrated) {
      branchInternalId
      branchExternalId
      courierName
    }
  }
`;
export const UPDATE_BRANCH = gql`
  mutation UpdateBranch(
    $id: String!
    $restaurantId: String!
    $titleEn: String!
    $titleAr: String!
    $addressEn: String
    $addressAr: String
    $buildingNumber: String
    $street: String
    $cityId: Int
    $block: String
    $deliveryNotes: String
    $contactNumber: String!
    $externalId: String
    $lat: Float!
    $lng: Float!
    $forceScheduledThreshold: Int!
    $pickupOrdersEnabled: Boolean!
    $pickupOrdersType: PickupType
    $deliveryOrdersEnabled: Boolean!
    $beachOrderEnabled: Boolean
    $dineIn: Boolean
    $busy: Boolean
    $openingHours: [WorkingDay]
  ) {
    updateBranch(
      id: $id
      restaurantId: $restaurantId
      titleEn: $titleEn
      titleAr: $titleAr
      addressEn: $addressEn
      addressAr: $addressAr
      buildingNumber: $buildingNumber
      street: $street
      cityId: $cityId
      block: $block
      deliveryNotes: $deliveryNotes
      contactNumber: $contactNumber
      externalId: $externalId
      lat: $lat
      lng: $lng
      forceScheduledThreshold: $forceScheduledThreshold
      pickupOrdersEnabled: $pickupOrdersEnabled
      pickupOrdersType: $pickupOrdersType
      deliveryOrdersEnabled: $deliveryOrdersEnabled
      beachOrderEnabled: $beachOrderEnabled
      dineIn: $dineIn
      busy: $busy
      openingHours: $openingHours
    ) {
      id
      titleEn
      titleAr
      addressEn
      addressAr
      contactNumber
      lat
      lng
      dineIn
      externalId
      busy
      delivery
      forceScheduledThreshold
      pickupOrdersEnabled
      pickupOrdersType
      deliveryOrdersEnabled
      beachOrderEnabled
      openingHours {
        day
        openAt
        closeAt
        offDay
      }
    }
  }
`;
