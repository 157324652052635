import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import ReactTooltip from 'react-tooltip';
import { Tag, Typography } from '@zydalabs/zac-react';
import { AlertTrianelgeIcon } from '@zydalabs/zac-icons-react';

import { Text } from 'components/service';
import { Button } from 'components/kit';
import {
  LEARN_MORE_HERE,
  CONNECT,
  DISCONNECT,
  COPY,
  IN_ARTICLE,
  GOOGLE_TRACKING_DRIVE_LEARN_MORE,
  UPGRADE_YOUR_FREE_PLAN,
} from 'constants/translations';
import { ReactComponent as UpgradeRocketIcon } from 'assets/upgrade-rocket.svg';
import Badge from 'common/components/Badge';
import { Input } from 'components/form/elements';
import { ReactComponent as AlertCircle } from 'assets/Icons_Warning.svg';
import PostfixLink from './PostfixLink';

const IntegrationsConnectionTemplate = ({
  imageSrc,
  imageAlt,
  title,
  subtitle,
  direction,
  isConnected,
  translate,
  upgradeable = false,
  upgradeText,
  badge = { hasBadge: false, title: '', color: '' },
  actionButtonSection = {
    hasButton: false,
    onClick: () => {
      // Do Nothing
    },
    buttonDisabled: false,
  },
  additionalInformationSection = { title: ['', ''], value: '', isCopyable: false, isDisaledInput: false },
  learnMoreUrl = '',
  isTablet = false,
  tooltip = { hasTooltip: false, title: '' },
  moreInfoUrl = '',
  extraInfo = '',
  isCapi = false,
  capiBlockedText,
}) => (
  <div>
    <div className="flex flex-col md:flex-row items-center px-4 py-6">
      <img
        src={imageSrc}
        alt={imageAlt}
        className={cx('w-12 self-start mb-3 sm:mb-0', direction === 'rtl' ? 'ml-3' : 'mr-3')}
      />
      <div className="flex-grow">
        <div className={cx('flex flex-row pb-2 items-center', isTablet && 'justify-between')}>
          <Text className={cx('font-semibold text-base w-auto', direction === 'ltr' ? 'pr-2' : 'pl-2')} value={title} />
          {badge.hasBadge && <Badge title={badge.title} color={badge.color} />}
          {upgradeable && (
            <div className="mx-1">
              <Tag
                color="orange"
                inner={{
                  text: translate(upgradeText),
                  icon: <UpgradeRocketIcon />,
                }}
                size="medium"
              />
            </div>
          )}
          {tooltip.hasTooltip && (
            <div>
              <AlertCircle
                data-tip
                data-for="googleAnaIdNote"
                className="text-gray-600 fill-current inline mr-2 ml-1 transform scale-75"
              />
              <ReactTooltip
                id="googleAnaIdNote"
                type="dark"
                effect="solid"
                globalEventOff="click"
                clickable
                className="max-w-lg"
                delayHide={1000}
              >
                <Text
                  value={tooltip.title}
                  postfixElement={moreInfoUrl && <PostfixLink url={moreInfoUrl} text={IN_ARTICLE} />}
                />
              </ReactTooltip>
            </div>
          )}
        </div>
        <div>
          <Text
            value={subtitle}
            postfixElement={learnMoreUrl && <PostfixLink url={learnMoreUrl} text={LEARN_MORE_HERE} />}
            className={cx(
              'text-sm font-normal opacity-75 mb-6 sm:mb-0',
              direction === 'ltr' ? 'pr-2 lg:pr-6' : 'pl-2 lg:pl-6',
            )}
          />
        </div>
        {extraInfo && (
          <div>
            <Text
              value={extraInfo}
              postfixElement={
                <PostfixLink
                  url="https://drive.google.com/drive/folders/1euLObWWAyi_KHHuYCfD9c4il-B44Jd2r?usp=sharing"
                  text={GOOGLE_TRACKING_DRIVE_LEARN_MORE}
                />
              }
              className={cx(
                'text-sm font-normal opacity-75 mb-6 sm:mb-0',
                direction === 'ltr' ? 'pr-2 lg:pr-6' : 'pl-2 lg:pl-6',
              )}
            />
          </div>
        )}
        {capiBlockedText && (
          <div
            style={{ direction }}
            className="w-full flex items-center border border-yellow-200 whitespace-nowrap mt-4 py-2 px-4 bg-yellow-50"
          >
            <AlertTrianelgeIcon width="20px" hanging="20px" color="#edb600" />
            <div className="flex items-center mx-3">
              <Typography variant="body12">
                <Text value={capiBlockedText} />
              </Typography>
            </div>
          </div>
        )}
      </div>
      {actionButtonSection.hasButton && (
        <Button
          kind={isConnected ? 'tertiaryLink' : 'tertiary'}
          className="self-start"
          size={isTablet ? 'full' : 'base'}
          onClick={upgradeable ? () => window.Intercom('show') : actionButtonSection.onClick}
          isSpinning={actionButtonSection.buttonDisabled && !isCapi}
          disabled={actionButtonSection.buttonDisabled}
        >
          <Text
            className="text-primary-base font-medium"
            value={upgradeable ? UPGRADE_YOUR_FREE_PLAN : isConnected ? DISCONNECT : CONNECT}
          />
        </Button>
      )}
    </div>
    {additionalInformationSection.value && (
      <div className="px-4">
        <hr className="self-center mb-5 bg-gray-400" />
        <div className="flex flex-col md:flex-row w-auto">
          {additionalInformationSection.title && (
            <Text
              className="font-semibold text-base opacity-75 w-auto pb-1 md:pb-0"
              value={additionalInformationSection.title}
            />
          )}
          {additionalInformationSection.isDisaledInput ? (
            <Input type="text" width="full" value={additionalInformationSection.value} disabled />
          ) : (
            <p className="font-normal text-base px-0 md:px-3 mb-6">{additionalInformationSection.value}</p>
          )}
          {additionalInformationSection.isCopyable && (
            <Button
              size="xs"
              className="ml-4"
              onClick={() => {
                navigator.clipboard.writeText(additionalInformationSection.value);
              }}
            >
              <Text value={COPY} />
            </Button>
          )}
        </div>
      </div>
    )}
  </div>
);

IntegrationsConnectionTemplate.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  imageAlt: PropTypes.string.isRequired,
  title: PropTypes.arrayOf(PropTypes.string).isRequired,
  subtitle: PropTypes.arrayOf(PropTypes.string).isRequired,
  upgradeText: PropTypes.arrayOf(PropTypes.string).isRequired,
  direction: PropTypes.oneOf(['ltr', 'rtl']).isRequired,
  isConnected: PropTypes.bool.isRequired,
  learnMoreUrl: PropTypes.string,
  upgradeable: PropTypes.bool,
  additionalInformationSection: PropTypes.shape({
    title: PropTypes.arrayOf(PropTypes.string),
    value: PropTypes.string,
    isCopyable: PropTypes.bool,
    isDisaledInput: PropTypes.bool,
  }),
  actionButtonSection: PropTypes.shape({
    hasButton: PropTypes.bool,
    onClick: PropTypes.func,
    buttonDisabled: PropTypes.bool,
  }),
  badge: PropTypes.shape({ hasBadge: PropTypes.bool, title: PropTypes.string, color: PropTypes.string }),
  isTablet: PropTypes.bool,
  isCapi: PropTypes.bool,
  capiBlockedText: PropTypes.arrayOf(PropTypes.string),
};

export default IntegrationsConnectionTemplate;
