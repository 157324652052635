import React, { useContext } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Button, Typography, useTheme } from '@zydalabs/zac-react';

import * as translations from 'constants/translations';
import { context as localeContext } from 'context/locale';
import TextDetails from './TextDetails';

const StoreRider = ({
  rider,
  verd,
  assignedDriverId,
  onToggle,
  isCourierNotDeclinedTheRequest,
  openOrderDeliveryConfirmationPopUp,
  handleUnassignDriverToOrder,
  handleAssignDriverToOrder,
}) => {
  const { lang, translate } = useContext(localeContext);
  const { colors } = useTheme();
  const isArabic = lang === 'ar';

  const riderHasFullCapacity = rider.assignedOrdersCount === verd?.driverMaxCapacity;

  return (
    <div className="p-2 w-100 flex flex-1 border-b justify-between">
      <span className="flex flex-col items-start" style={{ direction: lang === 'ar' ? 'rtl' : 'ltr' }}>
        <Typography variant="heading14">{rider.name}</Typography>
        <div>
          <div className={cx('text-xs inline-block w-auto', isArabic ? 'ml-1' : 'mr-1')}>
            <Typography variant="body12" color={riderHasFullCapacity ? colors.red[600] : colors.success.primary}>
              {translate(riderHasFullCapacity ? translations.MAX_CAPACITY : translations.AVAILABLE)}
            </Typography>
          </div>
          {rider.onShift && rider.assignedOrdersCount > 0 && (
            <div className="text-xs inline-block w-auto">
              <TextDetails>
                {translate(translations.UNDERSCORE)}
                {translate(translations.ASSIGNED_ORDERS(rider.assignedOrdersCount))}
              </TextDetails>
            </div>
          )}
        </div>
      </span>
      <div>
        {rider?.id === assignedDriverId ? (
          <Button
            text={translate(translations.UNASSIGN)}
            size="small"
            variant="tertiary"
            onClick={() => {
              onToggle();
              handleUnassignDriverToOrder();
            }}
          />
        ) : (
          <Button
            text={translate(translations.ASSIGN)}
            size="small"
            variant={riderHasFullCapacity ? 'primary' : 'tertiary'}
            disabled={riderHasFullCapacity}
            onClick={() => {
              onToggle();
              isCourierNotDeclinedTheRequest
                ? openOrderDeliveryConfirmationPopUp({
                    assignDriverToOrder: handleAssignDriverToOrder,
                    assignee: rider,
                  })
                : handleAssignDriverToOrder(rider);
            }}
          />
        )}
      </div>
    </div>
  );
};

StoreRider.propTypes = {
  rider: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    phoneNumber: PropTypes.string,
  }),
  verd: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    displayNameEn: PropTypes.string,
    displayNameAr: PropTypes.string,
  }),
  assignedDriverId: PropTypes.number,
  isCourierNotDeclinedTheRequest: PropTypes.bool,
  onToggle: PropTypes.func,
  openOrderDeliveryConfirmationPopUp: PropTypes.func,
  handleUnassignDriverToOrder: PropTypes.func,
  handleAssignDriverToOrder: PropTypes.func,
};

export default StoreRider;
