import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@zydalabs/zac-react';

import { Text } from 'components/service';
import { ORDER_STATUS } from 'constants/order';
import { ORDER_RIDER_STATUS } from 'constants/orderRiderStatus';
import * as translations from 'constants/translations';
import CancelOrderBody from './CancelOrderBody';
import TitleOrderNumber from '../../TitleOrderNumber';

const CancelOrder = ({ order, shouldDisableActionBtns, open, ordersVariables, close, status: CanceledOrderStatus }) => {
  const { paymentStatus, status, inBetweenTransitions, number, id } = order || {};
  const hasCourier = order.deliveryCourier && !order.deliveryCourier.isInternalDelivery;
  const deliveryOrderStatus = order?.deliveryStatus;
  const isCourierNotDeclinedTheRequest = hasCourier && deliveryOrderStatus !== ORDER_RIDER_STATUS.DECLINED;
  const isCourierSupportCancellation = order.deliveryCourier?.supportCancellation;
  const orderDeliveryCourierName = order.deliveryCourier?.courierDetails?.displayNameEn;
  const orderDeliverySupportNumber = order.deliveryCourier?.courierDetails?.supportNumber;

  return (
    ![ORDER_STATUS.CANCELED].includes(status) &&
    ![
      ORDER_STATUS.WAITING_FOR_PAYMENT,
      ORDER_STATUS.PAYMENT_FAILED,
      ORDER_STATUS.REDIRECT_URL,
      ORDER_STATUS.PAYMENT_EXPIRED,
      ORDER_STATUS.IFRAME_URL,
    ].includes(paymentStatus) && (
      <Button
        rounded
        isDisabled={shouldDisableActionBtns || inBetweenTransitions}
        onClick={() =>
          open({
            title: <TitleOrderNumber isCancel orderNumber={number} />,
            body: (
              <CancelOrderBody
                ordersVariables={ordersVariables}
                status={CanceledOrderStatus}
                isCourierNotDeclinedTheRequest={isCourierNotDeclinedTheRequest}
                isCourierSupportCancellation={isCourierSupportCancellation}
                orderDeliveryCourierName={orderDeliveryCourierName}
                orderDeliverySupportNumber={orderDeliverySupportNumber}
                orderId={id}
                onCancel={close}
                open={open}
              />
            ),
            size: 'max-w-sm',
          })
        }
        variant="danger-tertiary"
        size="medium"
        text={<Text value={translations.CANCEL_ORDER} />}
        data-testid="cancel-order-button"
      />
    )
  );
};

CancelOrder.propTypes = {
  order: PropTypes.shape({
    paymentStatus: PropTypes.string,
    status: PropTypes.string,
    inBetweenTransitions: PropTypes.bool,
    number: PropTypes.string,
    id: PropTypes.number,
    isScheduled: PropTypes.bool,
  }),
  shouldDisableActionBtns: PropTypes.bool,
  open: PropTypes.func,
  ordersVariables: PropTypes.shape({
    areas: PropTypes.arrayOf(PropTypes.string),
    branchId: PropTypes.string,
    customerName: PropTypes.string,
    deliveryType: PropTypes.arrayOf(PropTypes.string),
    number: PropTypes.number,
    page: PropTypes.number,
    paymentMethod: PropTypes.arrayOf(PropTypes.string),
    paymentStatuses: PropTypes.string,
    phone: PropTypes.string,
    status: PropTypes.string,
    statuses: PropTypes.string,
    storeId: PropTypes.string,
    submittedAt: PropTypes.string,
    voucherCode: PropTypes.string,
  }),
  close: PropTypes.func,
  status: PropTypes.string,
};
export default CancelOrder;
